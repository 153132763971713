  import React, { useState } from 'react';
  import Sidebar from '../components/Sidebar';
  import Header from '../components/Header';
  import holiday_email from '../assets/holiday_email.svg'
  import media_post from '../assets/medi_post.svg'
  import marketing from '../assets/marketing.svg'
  import invoice from '../assets/invoice.svg'
  import inventory from '../assets/inventory.svg'
  import research from '../assets/research.svg'
  import medical_report from '../assets/medical_report.svg'
  import client_feedback from '../assets/client_feedback.svg'
  import training_task from '../assets/training_task.svg'
  import blog from '../assets/blog.svg'
  import diet from '../assets/diet.svg'
  import support from '../assets/support.svg'
  import emergency from '../assets/emergency.svg'
  import Card from '../components/Card';

  const Dashboard = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
    return (
      <>
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="flex-1 lg:pl-64">
          <Header toggleSidebar={toggleSidebar} />
          <div className="p-6">
            <h2 className="md:text-xl text-lg font-semibold text-headingcolor mb-4">Marketing</h2>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
              <Card
                icon={holiday_email}
                title='Generate Clinic Holiday'
                description= 'Effortlessly create personalized holiday emails for your clients.'
                link='/assistant/1'
              />
              <Card
                icon={media_post}
                title='Create social media post'
                description= 'Quickly craft attention-grabbing social media posts for your clinic.'
                link='/assistant/2'
              />
              <Card
                icon={marketing}
                title='Marketing'
                description= 'Create tailored marketing strategies to grow your veterinary clinic.'
                link='/assistant/3'
              />
            </div>
          </div>
          <div className="p-6">
            <h2 className="md:text-xl text-lg font-semibold text-headingcolor mb-3">Finance</h2>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
              <Card
                icon={invoice}
                title='Billing and Invoicing'
                description= 'Simplify financial transactions with automated billing and invoicing tools.'
                link='/assistant/4'
              />
              <Card
                icon={inventory}
                title='Inventory Management'
                description= 'Track and manage clinic supplies and medications efficiently.'
                link='/assistant/5'
              />
            </div>
          </div>
          <div className="p-6">
            <h2 className="md:text-xl text-lg font-semibold text-headingcolor mb-3">Research</h2>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
              <Card
                icon={research}
                title='Research'
                description= 'Access the latest veterinary studies and insights with AI-powered research tools.'
                link='/assistant/6'
              />
              <Card
                icon={medical_report}
                title='Medical Record Summarizer'
                description= 'Quickly summarize patient records for easy reference.'
                link='/assistant/7'
              />
              <Card
                icon={client_feedback}
                title='Client Feedback Analysis '
                description= 'Analyze reviews and feedback to improve your clinic’s services.'
                link='/assistant/8'
              />
            </div>
          </div>
          <div className="p-6">
            <h2 className="md:text-xl text-lg font-semibold text-headingcolor mb-3">Others</h2>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
              <Card
                icon={training_task}
                title='IT and Training Tasks'
                description= 'Simplify IT management and staff training with AI-driven solutions.'
                link='/assistant/9'
              />
              <Card
                icon={support}
                title='Telemedicine Support'
                description= 'Offer remote consultations with secure video calls and chat.'
                link='/assistant/10'
              />
              <Card
                icon={blog}
                title='Write pet-owner education blog'
                description= 'Generate informative and engaging blog content for pet owners.'
                link='/assistant/11'
              />
              <Card
                icon={diet}
                title='Pet Diet & Nutrition Planner'
                description= 'Generate personalized diet plans for pets based on their needs.'
                link='/assistant/12'
              />
              <Card
                icon={emergency}
                title='Emergency Response Alerts'
                description= 'Automate and streamline emergency alerts for critical cases.'
                link='/assistant/13'
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  export default Dashboard;