import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import back from '../assets/back_icon.svg'
import Form1 from './Forms/Form1';

const AssistantForm = () => {
    const getid = useParams();
    const [status] = useState(getid.id);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

  return (
    <>
    <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <div className="w-full lg:pl-64">
            <Header toggleSidebar={toggleSidebar} />
            <div className="relative p-6">
                <Link to="/assistant" className="backbtn flex items-center gap-2 text-sm text-headingcolor absolute hover:text-primary">
                    <img src={back} className="h-3 w-3 object-contain" alt="back" />
                    <span>Back</span>
                </Link>
                <div className='bg-white rounded-lg p-6 w-full xl:max-w-2xl max-w-xl mx-auto mt-10 md:mt-0'>
                    {
                        status == 1 ? 
                        <Form1 />    
                        : 
                        status == 2 ? 
                        <Form1 />  
                        : 
                        <Form1 /> 
                    }
                </div>
            </div>
    </div>
    </>
  );
};

export default AssistantForm;