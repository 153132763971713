import React from 'react';
import { BrowserRouter as Route, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg'
import ai from '../assets/ai.svg'
import resources from '../assets/resources.svg'
import profile from '../assets/profile.svg'
import logout from '../assets/logout.svg'

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const location = useLocation();
  const navlinks = [
    {id: 1, title:'Ai Assistant', path:'/assistant', icon: ai},
    {id: 2, title:'Resources', path:'/resources', icon: resources},
    {id: 3, title:'Profile', path:'/profile', icon: profile},
    {id: 4, title:'Logout', path:'/logout', icon: logout},
  ]
  return (
    <>
      {/* Overlay for Mobile */}
      <div
        className={`fixed inset-0 bg-black opacity-50 z-40 lg:hidden ${isOpen ? 'block' : 'hidden'}`}
        onClick={toggleSidebar}
      ></div>

      {/* Sidebar Drawer */}
      <div
        className={`fixed h-screen shadow-md inset-y-0 left-0 transform lg:translate-x-0 z-50 w-64 bg-white text-black px-6 py-10 transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="text-2xl font-semibold">
          <img src={logo} alt="Logo" />
        </div>
        <nav className="mt-10">
          {navlinks.map((navlink) => (
            <Link key={navlink.id} to={navlink.path} className={`${location.pathname.startsWith(navlink.path) ? 'active' : ''} flex text-left gap-3 text-headingcolor hover:text-primary`}>
              <img src={navlink.icon} alt={navlink.title} className="hover:stroke-blue-400"/>
              {navlink.title}
            </Link>
          ))}
        </nav>
        <div className="absolute bottom-0 left-0 py-4 px-5">
          <span className="text-sm text-lightgray">Copyright @ 2024 All Right Reserved.</span>
        </div>
      </div>
    </>
  );
};

export default Sidebar;