import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import blog from '../../assets/blog.svg';
import clear from '../../assets/clear_icon.svg';

const Form1 = () => {
    const navigate = useNavigate();
    const initialFormdata = {
        write: '',
        want: '',
        length: '',
        tone: '',
        target: '',
    };
    const [formData,setFormData] = useState(initialFormdata);
    
    const handleChange = (e) => {
        const {name,value} = e.target;
        setFormData((prevData) => ({...prevData, [name]:value,}));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate('/assistant/result', {state: formData});
    }
  return (
    <>
    <button onClick={() => setFormData(initialFormdata)} className="themeColor mb-8 flex items-center gap-2.5 text-sm text-primary font-semibold">
        <img src={clear} className="h-3 w-3 object-contain" alt="back" />
        <span>Clear Inputs</span>
    </button>
    <div className="flex md:items-center md:flex-row flex-col gap-6 md:mb-8 mb-6">
        <img src={blog} className="w-14 h-14 object-contain md:ml-0" alt="services" />
        <div>
            <h5 className="font-bold font-Quicksand text-lg">Write pet-owner education blog</h5>
            <p className="text-para text-sm">Generate informative and engaging blog content for pet owners.</p>
        </div>
    </div>
    <form onSubmit={handleSubmit}>
        <div className="flex flex-wrap gap-y-8">
            <div className="w-full">
                <label className="md:text-base text-sm font-medium flex mb-4">I aim to write a blog focused on...</label>
                <input 
                    name="write" 
                    value={formData.write} 
                    className="w-full outline-none pb-2 font-medium text-sm text-headingcolor border-r-0 border-b border-lightgray p-0 hover:border-primary focus:border-primary"
                    onChange={handleChange} 
                />
            </div>
            <div className="md:w-1/2 w-full md:pr-4">
                <label className="md:text-base text-sm font-medium flex mb-4">Do you want the blog to be...</label>
                <select 
                    name='want' 
                    value={formData.want} 
                    className="w-full outline-none pb-2 font-medium text-sm text-headingcolor border-r-0 bg-transparent border-b border-lightgray p-0 hover:border-primary focus:border-primary"
                    onChange={handleChange}
                >
                    <option></option>
                    <option>Promotional</option>
                </select>
            </div>
            <div className="md:w-1/2 w-full md:pl-4">
                <label className="md:text-base text-sm font-medium flex mb-4">Length of the blog</label>
                <select 
                    name="length" 
                    value={formData.length} 
                    className="w-full outline-none pb-2 font-medium text-sm text-headingcolor border-r-0 bg-transparent border-b border-lightgray p-0 hover:border-primary focus:border-primary"
                    onChange={handleChange}
                >
                    <option></option>
                    <option>1000 words</option>
                </select>
            </div>
            <div className="md:w-1/2 w-full md:pr-4">
                <label className="md:text-base text-sm font-medium flex mb-4">Tone</label>
                <select 
                    name="tone" 
                    value={formData.tone} 
                    className="w-full outline-none pb-2 font-medium text-sm text-headingcolor border-r-0 bg-transparent border-b border-lightgray p-0 hover:border-primary focus:border-primary"
                    onChange={handleChange}
                >
                    <option></option>
                    <option>Friendly</option>
                </select>
            </div>
            <div className="md:w-1/2 w-full md:pl-4">
                <label className="md:text-base text-sm font-medium flex mb-4">Target audience</label>
                <select 
                    name="target" 
                    value={formData.target} 
                    className="w-full outline-none pb-2 font-medium text-sm text-headingcolor border-r-0 bg-transparent border-b border-lightgray p-0 hover:border-primary focus:border-primary"
                    onChange={handleChange}
                >
                    <option></option>
                    <option>Pet owners</option>
                </select>
            </div>
            <div className="w-full mt-3">
                <button className="h-12 md:h-10 w-full text-white text-base font-semibold bg-primary rounded-lg">Submit</button>
            </div>
        </div>
    </form>
    </>
  );
};

export default Form1;  