import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import back from '../assets/back_icon.svg'
import copy from '../assets/copy.svg'
import like from '../assets/like.svg'
import dislike from '../assets/dislike.svg'
import share from '../assets/share.svg'
import download from '../assets/download.svg'
import blog from '../assets/blog.svg'

const AssistantResult = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const api_key = "sk-7244bI9SHpzxH1zIYOhn7AJ3CTAh_uwkAjgwfUpMhuT3BlbkFJ1sAN7jO9ttvmBbCTjPA-_ptPqayHgUWHcx_62wKZMA";
    const {write} = location.state || {};
    const [responseData, setResponseData] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const postData = async () => {
          const data = {
            prompt: write,
            api_key: api_key
          };
    
          try {
            const response = await fetch('https://apis.celeritasdigital.com/gpt-api/api.php/gpt-api', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            });
    
            if (response.ok) {
              const jsonResponse = await response.json();
              setResponseData(jsonResponse.response);
            } else {
              console.error('Error:', response.statusText);
            }
          } catch (error) {
            console.error('Error:', error);
          }
        };
        postData();
      }, [prompt, api_key]);

  return (
    <>
    <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <div className="w-full lg:pl-64">
            <Header toggleSidebar={toggleSidebar} />
            <div className="relative p-6">
                <Link to="/assistant" className="backbtn flex items-center gap-2 text-sm text-headingcolor absolute hover:text-primary">
                    <img src={back} className="h-3 w-3 object-contain" alt="back" />
                    Back
                </Link>
                <div className='bg-white rounded-lg p-6 w-full xl:max-w-2xl max-w-xl mx-auto mb-6  mt-10 md:mt-0'>
                    <button onClick={() => navigate(-1)} className="themeColor md:mb-8 mb-6 flex items-center gap-2.5 text-sm text-primary font-semibold">
                        <img src={back} className="h-3 w-3 object-contain" alt="back" />
                        <span>Back to inputs</span>
                    </button>
                    <div className="flex md:items-center md:flex-row flex-col gap-6 md:mb-8 mb-6">
                        <img src={blog} className="w-14 h-14 object-contain md:ml-0" alt="services" />
                        <div>
                            <h5 className="font-bold font-Quicksand text-lg">Write pet-owner education blog</h5>
                            <p className="text-para text-sm">Generate informative and engaging blog content for pet owners.</p>
                        </div>
                    </div>
                    <div id="apiresult">
                        <h5 className="font-bold text-base capitalize mb-3">{write}</h5>
                        {responseData ? (
                            <p className="text-sm whitespace-pre-line">{responseData}</p>
                        ) : (
                            <p className="text-sm whitespace-pre-line">Recieving data...</p>
                        )}
                    </div>
                    <div className='actions flex gap-3.5 mt-3'>
                        <button><img src={copy} alt='copy'/></button>
                        <button><img src={like}  alt='like'/></button>
                        <button><img src={dislike} alt='dislike'/></button>
                        <button><img src={share} alt='share'/></button>
                        <button><img src={download} alt='download'/></button>
                    </div>
                </div>
            </div>
    </div>
    </>
  );
};

export default AssistantResult;