import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './screen/Dashboard';
import AssistantForm from './screen/AssistantForm';
import AssistantResult from './screen/AssistantResult';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to='/assistant' />} />
        <Route path="/assistant" element={<Dashboard />} />
        <Route path="/assistant/:id" element={<AssistantForm />} />
        <Route path="/assistant/result" element={<AssistantResult />} />
      </Routes>
    </Router>
  );
}

export default App;
